"use client";

import Image from "next/image";
import logoLight from "@/public/wealthadviser-logo.png";
import logoDark from "@/public/wealthadviser-logo-dark.png";
import { useTheme } from "next-themes";

export default function AppLogo() {
  const { resolvedTheme } = useTheme();

  return (
    <Image
      width={150}
      height={30}
      alt="Wealth Adviser logo"
      src={resolvedTheme === "dark" ? logoDark : logoLight}
    />
  );
}
