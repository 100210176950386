
import { Dispatch, SetStateAction } from "react";
import supabase from "@/lib/supabase.client";
import { getInitialLoginPath } from "@/server";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

interface HandleSignInArgs {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  email: string;
  password: string;
  router: AppRouterInstance;
  toast: any;
}

export const handleSignIn = async ({
  setIsLoading,
  email,
  password,
  router,
  toast,
}: HandleSignInArgs) => {
  setIsLoading(true);
  const { data, error } = await supabase.auth.verifyOtp({
    email: email,
    token: password,
    type: "magiclink",
  });

  if (error) {
    toast({
      title: "Whoops, something went wrong",
      description:
        "Please double check the code you entered is correct, or reach out to suppport.",
      variant: "destructive",
    });
    setIsLoading(false);
    return false;
  } else if (data) {
    toast({
      title: "Success",
      description: "You are now logged in",
      variant: "default",
    });
    const path = await getInitialLoginPath();
    router.push(path);
    setIsLoading(false);
    return true;
  }
};
