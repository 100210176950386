'use client';

import { useRouter } from 'next/navigation';
import { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Icons } from '@/components/icons';
import { toast } from '@/components/ui/use-toast';
import { handleSignIn } from './utils/handle-sign-in';
import { sendMagicLoginLink } from '@/server/users';

interface SigninArgs {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

export const Signin = ({ setIsLoading, isLoading }: SigninArgs) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formType, setFormType] = useState<'pending' | 'sent'>('pending');
  const router = useRouter();

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formType === 'pending') {
      setIsLoading(true);
      await sendMagicLoginLink(email);
      setIsLoading(false);
      setFormType('sent');
      document.getElementById('password')?.focus();
    } else if (formType === 'sent') {
      await handleSignIn({
        setIsLoading,
        email,
        password,
        router,
        toast,
      });
    }
  };

  return (
    <Card className='w-[450px]'>
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <CardContent>
          <div className='mt-6 grid w-full items-center gap-4'>
            {formType === 'pending' ? (
              <div className='flex flex-col space-y-1.5'>
                <Label htmlFor='email'>Email</Label>
                <Input
                  id='email'
                  type='email'
                  placeholder='Enter your email address...'
                  autoCapitalize='none'
                  autoComplete='email'
                  autoCorrect='off'
                  disabled={isLoading}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            ) : (
              <div className='flex w-full flex-col items-center space-y-6'>
                <div className='flex w-full flex-col space-y-1.5'>
                  <h3 className='text-lg font-medium text-primary'>
                    Check your email
                  </h3>
                  <p className='text-xs text-muted-foreground'>
                    If we find an account for <strong>{email}</strong>{' '}
                    we&apos;ll send a login code to your email.
                  </p>
                </div>

                <div className='flex w-full flex-col space-y-1.5'>
                  <Label htmlFor='name'>Code</Label>
                  <Input
                    className='text-center'
                    id='password'
                    type='text'
                    placeholder='Enter code...'
                    disabled={isLoading}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </div>
              </div>
            )}
          </div>
        </CardContent>
        <CardFooter>
          {formType === 'pending' && (
            <Button
              variant={'default'}
              disabled={isLoading}
              type='submit'
              className='w-full'
            >
              Continue
              {isLoading && (
                <Icons.spinner className='ml-2 h-4 w-4 animate-spin' />
              )}
            </Button>
          )}
          {formType === 'sent' && (
            <Button
              variant={'default'}
              disabled={isLoading}
              type='submit'
              className='w-full'
            >
              Continue with login code
              {isLoading && (
                <Icons.spinner className='ml-2 h-4 w-4 animate-spin' />
              )}
            </Button>
          )}
        </CardFooter>
      </form>
    </Card>
  );
};
