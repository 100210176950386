"use client";

import { useState } from "react";
import { Signin } from "./sign-in";

export default function AuthForm() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Signin
      setIsLoading={setIsLoading}
      isLoading={isLoading}
    />
  );
}
